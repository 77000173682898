import { MenuItem } from "./menu.model";
import { environment } from "@env/environment";

export const MENU_ITEMS: MenuItem[] = [
  {
    name: 'Dashboard', route: '/dashboard', children: [], icon: 'grid_view'
  },
  {
    name: 'Wallets', route: '/wallets', children: [], icon: 'account_balance_wallet'
  },
  {
    name: 'Payment Gateway', children: [
      {
        name: 'Pay-In', route: '/payment-gateway/pay-in', children: [], icon: ''
      },
      {
        name: 'Pay-Out', route: '/payment-gateway/pay-out', children: [], icon: ''
      },
      {
        name: 'Summary Report', route: '/summary-report', children: [], icon: ''
      }
    ], icon: 'account_balance'
  },
  {
    name: 'Settings', icon: 'settings', children: [
      {
        name: 'Profile', route: '/settings/profile', icon: '', children: []
      },
      {
        name: 'Security', route: '/settings/security', icon: '', children: []
      },
      // {
      //     name: 'Notifications', route: '/settings/notifications', icon: '', children: []
      // },
    ]
  },
  {
    name: 'Support', route: '/support', children: [], icon: 'support_agent'
  },
  {
    name: 'Documentation', icon: 'data_object', children: [
      {
        name: 'Introduction to CoiniGo', icon: '', localFileAddress: 'CoiniGo.pdf', children: []
      },
      {
        name: 'API Documentation', icon: '', url: `${environment.ipgUrl}api/docs`, children: []
      },
      {
        name: 'Webhook', icon: '', url: `${environment.ipgUrl}swagger/index.html`, children: []
      },
    ]
  },
]
